import StationMails from "."

const StationMailsRoutes = [
    {
        path: "/station-mails",
        index: true,
        element: <StationMails />
    }
]

export default StationMailsRoutes
import React from "react";
import { Button } from "primereact/button";

const IconButton = ({ tooltip = "", icon, onClick = () => {}, ...props }) => {
  return (
    <Button
      text
      style={{ padding: 0, marginLeft: 10 }}
      tooltip={tooltip}
      onClick={onClick}
      {...props}
    >
      {icon}
    </Button>
  );
};

export default IconButton;

import React, { Fragment, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Row,
  Col,
  Label,
  InputGroup,
} from "reactstrap";
import {
  useGetMessageTemplateQuery,
  useDeleteMessageTemplateMutation,
} from "@src/redux/messageTemplates";
import { useNavigate, useParams } from "react-router-dom";
import { ReadonlyInput } from "@src/components/inputs";
import Loader from "@src/components/shared/Loader";
import {
  faEarthAsia,
  faHashtag,
  faMessage,
  faBoxesPacking,
  faUsers,
  faUserTie,
  faFontAwesome,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CopyToClipboard from "@src/components/shared/CopyToClipboard";
import { Button } from "primereact/button";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";

const Details = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isFetching, error } = useGetMessageTemplateQuery(id);
  const [deleteMessageTemplate] = useDeleteMessageTemplateMutation();

  const getMessageType = (type) => {
    switch (type) {
      case "ldm":
        return faBoxesPacking;
      case "prl":
        return faUsers;
      case "apis":
        return faUserTie;
    }
  };

  const handleDeleteClick = async () => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Delete this message template?",
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-danger ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await deleteMessageTemplate(data.id)
          .unwrap()
          .then(() => {
            openSweetAlert({
              title: "Message template deleted Successfully!",
            });
            navigate(`/sita-messages/messageTemplates`);
          });
      },
    });
  };

  if (isFetching) {
    return <Loader />;
  }
  return (
    <Fragment>
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Message Template Details</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="info-container">
            <br />
            {data ? (
              <>
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Template Name"
                        name="name"
                        icon={faHashtag}
                        defaultValue={data.name}
                      />
                    </div>
                    <div className="mb-1">
                      <Label className="form-label" for="messageType">
                        <h6>Message Type</h6>
                      </Label>
                      <InputGroup>
                        <span className="p-inputgroup-addon text-primary">
                          <FontAwesomeIcon icon={faFontAwesome} />
                        </span>
                        <span
                          className="form-control input-group text-uppercase"
                          style={{ backgroundColor: "#efefef", opacity: 1 }}
                        >
                          <FontAwesomeIcon
                            icon={getMessageType(data.messageType)}
                            className="font-medium-3 text-body"
                          />
                          &nbsp;
                          {data.messageType}
                        </span>
                      </InputGroup>
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Country"
                        name="country"
                        icon={faEarthAsia}
                        defaultValue={data.country}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Station"
                        name="station"
                        icon={faBuilding}
                        defaultValue={data.station}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Template Content"
                        name="content"
                        icon={faMessage}
                        type="textarea"
                        rows="10"
                        defaultValue={data.content}
                      />
                      <CopyToClipboard
                        value={data.content}
                        defaultMsg="Click to copy"
                        successMsg="Copied to clipboard!"
                      ></CopyToClipboard>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col>
                  <p>{error ? error : "No data available."}</p>
                </Col>
              </Row>
            )}
          </div>
        </CardBody>
        <CardFooter>
          <Button
            btn-secondary
            color="primary"
            onClick={() => {
              navigate(`/sita-messages/messageTemplates/edit/${data.id}`);
            }}
          >
            Edit
          </Button>
          <Button
            c
            color="secondary"
            className="mx-1 btn-secondary"
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default memo(Details);

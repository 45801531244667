import { useNavigate } from "react-router-dom";
import Table from "@src/components/DataTable/Table";
import { Card, CardBody, Button } from "reactstrap";
import {
  useGetMessageTemplatesQuery,
  useDeleteMessageTemplateMutation,
  useSyncMessageTemplateMutation,
} from "@src/redux/messageTemplates";
import messageTemplatesColumns from "./Columns";
import { useState } from "react";
import { File } from "react-feather";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";

const MessageTemplats = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    country: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    station: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    messageType: { value: null, matchMode: FilterMatchMode.EQUALS },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);
  const [deleteMessageTemplate] = useDeleteMessageTemplateMutation();
  const [syncMessageTemplate] = useSyncMessageTemplateMutation();
  const { data, isLoading } = useGetMessageTemplatesQuery(filters);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const handleDeleteClick = async (item) => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Delete this message template?",
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-danger ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await deleteMessageTemplate(item.id)
          .unwrap()
          .then(() => {
            openSweetAlert({
              title: "Message template deleted Successfully!",
            });
          });
      },
    });
  };

  const actionTemplate = () => {
    return (
      <Button.Ripple
        color="dark"
        className="me-1"
        outline
        onClick={handleSyncTemplates}
      >
        <File size={18} />
        &nbsp; Sync Files
      </Button.Ripple>
    );
  };

  const handleSyncTemplates = async () => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to sync templates with files?",
      text: "You can delete the teamplates with no files",
      showDenyButton: true,
      icon: "question",
      confirmButtonText: "Sync only",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-danger ms-1",
      cancelButtonText: "Cancel",
      denyButtonText: `Sync and delete`,
      onConfirm: async () => {
        SyncFunc(false);
      },
      onDeny: async () => {
        SyncFunc(true);
      },
    });
  };

  const SyncFunc = async (deleteFiles) => {
    await syncMessageTemplate({
      deleteTemplateWithNoFile: deleteFiles,
    })
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: "Message templates synced Successfully!",
        });
      });
  };

  return (
    <>
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={messageTemplatesColumns(handleDeleteClick)}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Message Template Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            displayAdd={true}
            handleAdd={() => {
              navigate(`/sita-messages/messageTemplates/add`);
            }}
            actionTemplate={actionTemplate}
            tableState={filters}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default MessageTemplats;

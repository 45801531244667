//  import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/primereact.min.css';
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState, useRef } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Filter, Search, Plus } from "react-feather";

const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
const paginatorRight = <Button type="button" icon="pi pi-download" text />;

function Table({
  columns = [],
  values,
  metaData,
  tableState,
  setTableState,
  totalRecords,
  rowExpansionTemplate,
  advancedFilterLogic = "and",
  emptyMessage = "No data found.",
  clearFilter,
  displayAdd,
  handleAdd,
  actionTemplate = () => {},
  ...props
}) {
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const { currentPage, pageSize, totalCount, totalPages, loading } = metaData;
  const [filter, setFilter] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: {},
    keyword: "",
  });
  const { globalFilterFields } = props;
  const onPage = (e) => {
    setTableState({
      ...tableState,
      pageNumber: e.page + 1,
      pageSize: e.rows,
    });
  };

  const onSort = (event) => {
    let order = [`${event.sortField}`];

    if (tableState.orderBy && tableState.orderBy[0] === order + " " + "Asc") {
      order += " Desc";
    } else {
      order += " Asc";
    }
    order = [order];

    setTableState({
      ...tableState,
      orderBy: order,
    });
  };

  const getFilterOperator = (operator) => {
    switch (operator) {
      case "equals" || "dateIs":
        return "eq";
      case "dateBefore":
        return "lt";
      case "dateAfter":
        return "gt";
      case "dateIsNot":
        return "neq";
      case "notEquals":
        return "neq";
      default:
        return operator;
    }
  };

  const onFilter = (e) => {
    const filters = Object.entries(e.filters).map(([key, value]) => {
      if (!value.constraints) {
        if (value.value === null || value.value === "") return null;
        return {
          field: key,
          operator: getFilterOperator(value.matchMode),
          value: value.value,
        };
      }
      if (value.constraints && value.constraints.length > 1) {
        return {
          logic: value.operator,
          filters: value.constraints.map((constraint) => {
            if (constraint.value !== null && constraint.value !== "")
              return {
                field: key,
                operator: getFilterOperator(constraint.matchMode),
                value: constraint.value,
              };
          }),
        };
      } else {
        if (
          value.constraints[0].value === null ||
          value.constraints[0].value === ""
        )
          return null;
        return {
          field: key,
          operator: getFilterOperator(value.constraints[0].matchMode),
          value: value.constraints[0].value,
        };
      }
    });
    setTableState({
      ...tableState,
      advancedFilter: {
        logic: advancedFilterLogic,
        filters: filters.filter((f) => f !== null),
      },
      pageNumber: e.first + 1,
      pageSize: e.rows,
    });
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setVisibleColumns(orderedSelectedColumns);
  };

  const onRowExpand = (event) => {
    toast.current.show({
      severity: "info",
      summary: "Product Expanded",
      detail: event.data.name,
      life: 3000,
    });
  };

  const onRowCollapse = (event) => {
    toast.current.show({
      severity: "success",
      summary: "Product Collapsed",
      detail: event.data.name,
      life: 3000,
    });
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between">
        <Button type="button" onClick={clearFilter} outlined>
          <Filter size={18} /> Clear
        </Button>
        <div>
          {actionTemplate()}
          {displayAdd ? (
            <Button severity="primary" onClick={handleAdd}>
              <Plus size={18} /> New
            </Button>
          ) : (
            ""
          )}
          {/* <span className="p-input-icon-left">
                    <Search size={15}/>
                    <InputText placeholder="Keyword Search" />
                </span> */}
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <Toast ref={toast} />
      <DataTable
        lazy
        value={values}
        paginator
        showGridlines
        rows={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50]}
        loading={loading}
        onFilter={onFilter}
        onSort={onSort}
        header={header}
        onPage={onPage}
        paginatorPosition="both"
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorRight}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        first={(currentPage - 1) * pageSize}
        totalRecords={totalCount}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        {...props}
        emptyMessage={emptyMessage}
      >
        {/* {props.children} */}
        {expandedRows && <Column expander={true} style={{ width: "3rem" }} />}
        {visibleColumns.map((col, i) => (
          <Column
            key={col.field}
            className="font-bold"
            filter={col.filter}
            sortable={col.sortable}
            filterPlaceholder="Search..."
            field={col.field}
            dataType={col.dataType}
            header={col.header}
            body={col.body}
            filterElement={col.filterElement}
            showFilterMatchModes={col.showFilterMatchModes}
            filterField={col.filterField}
          />
        ))}
      </DataTable>
    </>
  );
}

export default Table;

import MessageTemplats from ".";
import Details from "./details";
import MessageTemplateForm from "./MessageTemplateForm";

const MessageTemplatesRoutes = [
  {
    path: "/messageTemplates",
    index: true,
    element: <MessageTemplats />,
  },
  {
    path: "/messageTemplates/:id",
    index: true,
    element: <Details />,
  },
  {
    path: "/messageTemplates/edit/:id",
    index: true,
    element: <MessageTemplateForm />,
  },
  {
    path: "/messageTemplates/add",
    index: true,
    element: <MessageTemplateForm />,
  },
];

export default MessageTemplatesRoutes;

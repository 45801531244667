import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetStationMailsQuery,
  useDeleteStationMailMutation,
} from "@src/redux/stationmails";
import stationMailsColumns from "./Columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import StationMailsModal from "./StationMailsModal";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";

const StationMails = () => {
  const [editData, setEditData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState("add");
  const [deleteStationMail] = useDeleteStationMailMutation();

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    station: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    icao: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    active: { value: null, matchMode: FilterMatchMode.EQUALS },
    mails: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading } = useGetStationMailsQuery(filters);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const handleAddClick = () => {
    setEditData(null);
    setMode("add");
    setVisible(true);
  };

  const handleEditClick = (item) => {
    setEditData(item);
    setMode("update");
    setVisible(true);
  };

  const handleDeleteClick = async (item) => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Delete this station mail?",
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-danger ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await deleteStationMail(item.id)
          .unwrap()
          .then(() => {
            openSweetAlert({
              title: "Station mail deleted Successfully!",
            });
          });
      },
    });
  };

  return (
    <>
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={stationMailsColumns(handleEditClick, handleDeleteClick)}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Station Mails Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            displayAdd={true}
            handleAdd={handleAddClick}
            tableState={filters}
          />
        </CardBody>
      </Card>
      <StationMailsModal
        visible={visible}
        toggle={() => setVisible(false)}
        editData={editData}
        setEditData={setEditData}
        mode={mode}
      />
    </>
  );
};

export default StationMails;

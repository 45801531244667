import { mainApi } from "./mainApi";

export const notificationsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (filters) => ({
        url: `/notifications/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
    }),
    getNotification: builder.query({
      query: (id) => ({
        url: `/notifications/${id}`,
        method: "GET",
      }),
    }),
    exportNotifications: builder.mutation({
      query: (filters) => ({
        url: `/notifications/export`,
        method: "POST",
        body: filters,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNotificationsQuery,
  useGetNotificationQuery,
  useExportNotificationsMutation,
} = notificationsApi;

import Flights from "./index"
import Details from "./details"

const FlightsRoutes = [
    {
        path: "/flights",
        index: true,
        element: <Flights />
    },
    {
        path: "/flights/:id",
        index: true,
        element: <Details />
    }
]

export default FlightsRoutes

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faPenToSquare,
  faTrashCan,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

const StationMailsColumns = (handleEditClick, handleDeleteClick) => {
  const ActivationStatusBodyTemplate = ({ active }) => (
    <span className="text-capitalize">
      <FontAwesomeIcon
        icon={getActivationStatusIcon(active.toString())}
        className={getActivationStatusClass(active.toString())}
        style={{ fontSize: "1.3rem" }}
        aria-hidden="true"
      />
      <span className="sr-only">
        {active === "true" ? "Active" : "Inactive"}
      </span>
    </span>
  );
  const getActivationStatusIcon = (active) =>
    active === "true" ? faCircleCheck : faCircleXmark;

  const getActivationStatusClass = (active) =>
    active === "true" ? "text-success" : "text-danger";

  const ActivationStatuses = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  const ActivationStatusFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={ActivationStatuses}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={ActivationStatusItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
  const ActivationStatusItemTemplate = ({ label, value }) => (
    <span className="text-capitalize">
      <FontAwesomeIcon
        icon={getActivationStatusIcon(value)}
        className={getActivationStatusClass(value)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {label}
    </span>
  );
  const actionsBodyTemplate = (rowData, handleEditClick, handleDeleteClick) => {
    return (
      <>
        <Button
          severity="secondary"
          text
          onClick={() => {
            handleEditClick({ ...rowData });
          }}
        >
          <span className="text-capitalize">
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{ fontSize: "1.3rem" }}
            />
          </span>
        </Button>
        <Button
          tooltip="Delete"
          tooltipOptions={{ position: "top" }}
          severity="secondary"
          text
          onClick={() => {
            handleDeleteClick({ ...rowData });
          }}
        >
          <span className="text-capitalize">
            <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: "1.3rem" }} />
          </span>
        </Button>
      </>
    );
  };

  return [
    {
      header: "Station",
      filter: true,
      field: "station",
      sortable: false,
    },
    {
      header: "ICAO",
      filter: true,
      field: "icao",
      sortable: false,
    },
    {
      header: "Active",
      filter: true,
      field: "active",
      sortable: false,
      body: ActivationStatusBodyTemplate,
      filterElement: ActivationStatusFilterTemplate,
      showFilterMatchModes: false,
      dataType: "boolean",
      bodyClassName: "text-center",
    },
    {
      header: "Mails",
      filter: true,
      field: "mails",
      sortable: false,
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      body: (rowData) =>
        actionsBodyTemplate(rowData, handleEditClick, handleDeleteClick),
    },
  ];
};

export default StationMailsColumns;

import {
  Home,
  MessageSquare,
  FileText,
  List,
  Settings,
  Send,
  Mail,
  Bookmark,
  Bell,
  Sliders,
  UploadCloud,
} from "react-feather";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faBoxesPacking,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";

export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/sita-messages/home",
  },
  {
    id: "flights",
    title: "Flights",
    icon: <Send size={20} />,
    navLink: "/sita-messages/flights",
  },
  {
    id: "messages",
    title: "Messages",
    icon: <MessageSquare size={20} />,
    children: [
      {
        id: "prl",
        title: "PRL Messages",
        icon: <FontAwesomeIcon icon={faUsers} style={{ fontSize: "1.3rem" }} />,
        navLink: "/sita-messages/prl-messages",
      },
      {
        id: "ldm",
        title: "LDM Messages",
        icon: (
          <FontAwesomeIcon
            icon={faBoxesPacking}
            style={{ fontSize: "1.3rem" }}
          />
        ),
        navLink: "/sita-messages/ldm-messages",
      },
      {
        id: "Apis",
        title: "APIS Messages",
        icon: (
          <FontAwesomeIcon icon={faUserTie} style={{ fontSize: "1.3rem" }} />
        ),
        navLink: "/sita-messages/apis-messages",
      },
    ],
  },
  {
    id: "loadSheets",
    title: "Load Sheets",
    icon: <FileText size={20} />,
    children: [
      {
        id: "loadSheetsList",
        title: "List",
        icon: <List size={12} />,
        navLink: "/sita-messages/load-sheets",
      },
      {
        id: "uploadLoadSheet",
        title: "upload",
        icon: <UploadCloud size={12} />,
        navLink: "/sita-messages/load-sheets/upload",
      },
      {
        id: "stationMails",
        title: "Station Mails",
        icon: <Mail size={12} />,
        navLink: "/sita-messages/station-mails",
      },
    ],
  },
  // {
  //   id: "notifications",
  //   title: "Notifications",
  //   icon: <Bell size={20} />,
  //   navLink: "/sita-messages/notifications",
  // },
  {
    id: "settings",
    title: "Settings",
    icon: <Settings size={20} />,
    children: [
      {
        id: "messagetemplates",
        title: "Message Templates",
        icon: <FileText size={20} />,
        navLink: "/sita-messages/messageTemplates",
      },
      {
        id: "messageSenders",
        title: "Message Senders",
        icon: <Send size={20} />,
        navLink: "/sita-messages/messageSenders",
      },
      {
        id: "apisSettings",
        title: "Apis Settings",
        icon: <Sliders size={20} />,
        navLink: "/sita-messages/apisSettings",
      },
      {
        id: "messageAddresses",
        title: "Message Addresses",
        icon: <Bookmark size={20} />,
        navLink: "/sita-messages/messageAddresses",
      },
    ],
  },
];

import React, { Fragment, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Badge,
  CardSubtitle,
} from "reactstrap";
import {
  useGetFlightQuery,
  useGenerateMessageMutation,
} from "@src/redux/flights";
import { useParams, Link } from "react-router-dom";
import Loader from "@src/components/shared/Loader";
import MessageStatusAvatar from "@src/components/Messages/MessageStatusAvatar";
import {
  faPlane,
  faPlaneDeparture,
  faUsers,
  faBoxesPacking,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { Eye, FileText } from "react-feather";
import { formatDateTime } from "@src/utility/Utils";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import Avatar from "@src/@core/components/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const Details = () => {
  const { id } = useParams();
  const { data, isFetching, error } = useGetFlightQuery(id);
  const [generateMessage, { isLoading: loadingGenerateMessage }] =
    useGenerateMessageMutation();

  const handleGenerateMessage = async (messageType) => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to generate message",
      icon: "question",
      confirmButtonText: "Generate",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await generateMessage({
          flightId: id,
          messageType: messageType,
        })
          .unwrap()
          .then(() => {
            openSweetAlert({
              title: "Message generated Successfully!",
            });
          });
      },
    });
  };
  if (isFetching || loadingGenerateMessage) {
    return <Loader />;
  }

  return (
    <Fragment>
      <BreadCrumbs pageName="flightDetails" pageTitle="Flight Details" />
      {data ? (
        <>
          <Row>
            <Col lg="8" md="12" xs="12">
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle tag="h1" className="fw-bolder">
                    <strong>
                      <span className="text-primary">
                        <FontAwesomeIcon icon={faPlane} />
                      </span>{" "}
                      <span className="text-primary">Flight</span>
                      {"  "}
                      <span class="fw-bolder mb-25">
                        {" "}
                        XY{data.flightNo}
                      </span>{" "}
                      <span class="font-small-2 text-muted">Flight No</span>
                      {data.registration ? (
                        <>
                          <span className="text-muted"> {"       "}</span>
                          &nbsp;&nbsp;-&nbsp;&nbsp;
                          <span class="fw-bolder mb-25">
                            {" "}
                            {data.registration}
                          </span>{" "}
                          <span class="font-small-2 text-muted">
                            Registration
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </strong>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="info-container">
                    <div
                      className="d-flex justify-content-around my-2 pt-75"
                      style={{ alignItems: "center" }}
                    >
                      <div className="d-flex align-items-start me-1 p-1 rounded border col-sm-3">
                        <Row tag="dl" className="mb-0">
                          <Col tag="dt" sm="3" className="fw-bolder  pb-50 ">
                            <Badge
                              color="light-primary"
                              className="rounded p-75"
                            >
                              <FontAwesomeIcon
                                icon={faPlaneDeparture}
                                className="font-medium-3"
                              />
                            </Badge>
                          </Col>
                          <Col tag="dd" sm="9" className=" pb-50 ">
                            <h4 className="fw-bolder">
                              Departure{" "}
                              <span class="font-small-2 text-muted">
                                {data.departureCountry}
                              </span>
                              <br />
                              <p className="text-muted m-0 font-small-2">
                                {data.departure} / {data.departureIcao}
                              </p>
                            </h4>
                          </Col>
                          <hr />
                          <Col tag="dd" sm="12">
                            <span className="fw-bolder">STD: </span>
                            {data.std && formatDateTime(new Date(data.std))}
                          </Col>
                          <Col tag="dd" sm="12">
                            <span className="fw-bolder">UTC: </span>
                            {data.stdutc &&
                              formatDateTime(new Date(data.stdutc))}
                          </Col>
                          {data.etd != "0001-01-01T00:00:00" && data.etd ? (
                            <Col tag="dd" sm="12">
                              <span className="fw-bolder">ETD: </span>
                              {data.etd && formatDateTime(new Date(data.etd))}
                            </Col>
                          ) : null}
                        </Row>
                      </div>
                      {data.divertedTo ? (
                        <>
                          <div
                            className="d-flex me-1 p-1"
                            style={{ alignItems: "center" }}
                          >
                            <span
                              className="text-primary"
                              style={{ fontSize: "1.5rem" }}
                            >
                              .. <FontAwesomeIcon icon={faPlane} /> ..
                            </span>
                          </div>
                          <div
                            className="d-flex align-items-start me-1 p-1 rounded border"
                            style={{ height: "fit-content" }}
                          >
                            <div className="ms-0">
                              <h4 className="fw-bolder border-bottom pb-50 mb-1">
                                Diversion
                              </h4>
                              <p className="text-muted m-0">
                                {data.divertedTo} / {data.divertedToIcao}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div
                        className="d-flex me-1 p-1"
                        style={{ alignItems: "center" }}
                      >
                        <span
                          className="text-primary"
                          style={{ fontSize: "1.5rem" }}
                        >
                          .. <FontAwesomeIcon icon={faPlane} /> ..
                        </span>
                      </div>
                      <div className="d-flex align-items-start p-1 rounded border col-sm-3">
                        <Row tag="dl" className="mb-0">
                          <Col tag="dt" sm="3" className="fw-bolder  pb-50 ">
                            <Badge
                              color="light-primary"
                              className="rounded p-75"
                            >
                              <FontAwesomeIcon
                                icon={faPlaneDeparture}
                                className="font-medium-3"
                              />
                            </Badge>
                          </Col>
                          <Col tag="dd" sm="9" className=" pb-50 ">
                            <h4 className="fw-bolder">
                              Arrival{" "}
                              <span class="font-small-2 text-muted">
                                {" "}
                                {data.arrivalCountry}
                              </span>
                              <br />
                              <p className="text-muted m-0 font-small-2">
                                {data.arrival} / {data.arrivalIcao}
                              </p>
                            </h4>
                          </Col>
                          <hr />
                          <Col tag="dd" sm="12">
                            <span className="fw-bolder">STA: </span>
                            {data.sta && formatDateTime(new Date(data.sta))}
                          </Col>
                          <Col tag="dd" sm="12">
                            <span className="fw-bolder">UTC: </span>
                            {data.stautc &&
                              formatDateTime(new Date(data.stautc))}
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <h4 className="border-bottom pb-50 mb-1 text-muted">
                      <span className="text-primary">
                        <FontAwesomeIcon icon={faUserTie} />
                      </span>{" "}
                      Crews
                    </h4>
                    <div className="info-container mb-1">
                      <Row>
                        <Col xl="4" xs="12">
                          <Row>
                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Crew Count:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.crewCount}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl="4" xs="12">
                          <Row>
                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Cabin Crew Count:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.cabinCewCount}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl="4" xs="12">
                          <Row tag="dl" className="mb-0">
                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Cockpit Crew Count:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.cockpitCrewCount}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <h4 className="border-bottom pb-50 mb-1 text-muted">
                      <span className="text-primary">
                        <FontAwesomeIcon icon={faUsers} />
                      </span>{" "}
                      Pax
                    </h4>
                    <div className="info-container">
                      <Row>
                        <Col xl="4" xs="12">
                          <Row tag="dl" className="mb-0">
                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Onward Pax:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.onwardPassengersCount}
                            </Col>

                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Inbound Pax:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.inboundPassengersCount}
                            </Col>

                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Total Baggage Count:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.totalBaggageCount}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl="4" xs="12">
                          <Row tag="dl" className="mb-0">
                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Booked Business:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.bookedBusinessCount}
                            </Col>

                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Booked Economy:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.bookedEconomyCount}
                            </Col>

                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Total Baggage Weight:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.totalBaggageWeight}
                            </Col>
                          </Row>
                        </Col>
                        <Col xl="4" xs="12">
                          <Row tag="dl" className="mb-0">
                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Business Capacity:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.businessCapacity}
                            </Col>
                            <Col tag="dt" sm="9" className="fw-bolder mb-1">
                              Economy Capacity:
                            </Col>
                            <Col tag="dd" sm="3" className="mb-1">
                              {data.economyCapacity}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="6" xs="12">
              <Card className="card-transaction">
                <CardHeader>
                  <CardTitle tag="h4">Flight Messages</CardTitle>
                </CardHeader>
                <CardBody>
                  {" "}
                  <MessageStatusAvatar
                    status={data.prlMessageStatus}
                    linkUrl={`/sita-messages/prl-messages/${data.id}/1`}
                    label="Prl Message Status"
                    id={data.prlMessageId}
                    handelGenerateMessage={() => handleGenerateMessage("prl")}
                    key="prlMessageStatus"
                    typeIcon={faUsers}
                  />
                  <MessageStatusAvatar
                    status={data.ldmMessageStatus}
                    linkUrl={`/sita-messages/ldm-messages/${data.ldmMessageId}`}
                    label="LDM Message Status"
                    id={data.ldmMessageId}
                    handelGenerateMessage={() => handleGenerateMessage("ldm")}
                    key="ldmMessageStatus"
                    typeIcon={faBoxesPacking}
                  />
                  <MessageStatusAvatar
                    status={data.inBoundApisMessageStatus}
                    linkUrl={`/sita-messages/apis-messages/${data.inBoundApisMessageId}`}
                    label="In Bound APIS Message Status"
                    id={data.inBoundApisMessageId}
                    handelGenerateMessage={() => handleGenerateMessage("apis")}
                    key="inBoundApisMessageStatus"
                    typeIcon={faUserTie}
                  />
                  <MessageStatusAvatar
                    status={data.outBoundApisMessageStatus}
                    linkUrl={`/sita-messages/apis-messages/${data.outBoundApisMessageId}`}
                    label="Out Bound APIS Message Status"
                    id={data.outBoundApisMessageId}
                    handelGenerateMessage={() => handleGenerateMessage("apis")}
                    key="outBoundApisMessageStatus"
                    typeIcon={faUserTie}
                  />
                </CardBody>
              </Card>
              <Card className="card-transaction">
                <CardHeader>
                  <CardTitle tag="h4">Load-Sheet Status</CardTitle>
                  {data.loadSheetReminderSent ? (
                    <CardSubtitle className="font-small-2 text-muted">
                      Reminder has been Sent
                    </CardSubtitle>
                  ) : null}
                </CardHeader>
                <CardBody>
                  {" "}
                  {data.flightLoadSheets && data.flightLoadSheets.length > 0 ? (
                    <>
                      {data.flightLoadSheets.map((item, index) => (
                        <div key={index} className="transaction-item">
                          <div className="d-flex">
                            <Avatar
                              className="rounded"
                              color={"light-primary"}
                              icon={<FileText style={{ fontSize: "1.3rem" }} />}
                            />
                            <div>
                              <h6 className="transaction-title">
                                {item.subject}
                              </h6>
                            </div>
                          </div>
                          <div className="fw-bolder text-success">
                            <Link
                              to={`/sita-messages/load-sheets/${item.id}`}
                              title={`Click here to view Loadsheet details for ${item.subject}`}
                            >
                              <Eye
                                className="font-medium-3 text-body"
                                title="Show load-sheet"
                                style={{ marginLeft: 10 }}
                              />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div key="noLoadsheet">
                        <div className="d-flex">
                          <h6 className="text-muted">
                            {" "}
                            No Loadsheets for this flight
                          </h6>
                        </div>
                      </div>{" "}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            <p>No data available.</p>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default memo(Details);

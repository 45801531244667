import React, { useState } from 'react'
import { Copy } from 'react-feather'
import copy from 'copy-to-clipboard'
import { Link } from 'react-router-dom'

const CopyToClipboard = ({ value, defaultMsg = '', successMsg = 'Copied!', iconSize = 16 }) => {
    const [showAlert, setShowAlert] = useState(false)

    const handleCopyClick = (e) => {
        e.preventDefault()
        copy(value)
        setShowAlert(true)
        setTimeout(() => {
            setShowAlert(false)
        }, 2000)
    }

    return (
        <>
            <Link onClick={handleCopyClick}> <Copy size={iconSize} /> {showAlert ? successMsg : defaultMsg} </Link> 
        </>
    )
}

export default CopyToClipboard

import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
  Label,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import * as yup from "yup";
import { TextInput } from "@src/components/inputs";
import {
  useUpdateApisSettingsMutation,
  useAddApisSettingsMutation,
} from "@src/redux/ApisSettings";
import { faEarthAsia, faTag } from "@fortawesome/free-solid-svg-icons";
import { Checkbox } from "primereact/checkbox";

const ApisSettingModal = ({ visible, toggle, editData, setEditData, mode }) => {
  const [updateApisSettings] = useUpdateApisSettingsMutation();
  const [addApisSettings] = useAddApisSettingsMutation();

  const apisSettingsSchema = yup.object().shape({
    country: yup
      .string()
      .nullable()
      .test(
        "len",
        "Country must be 2 characters in length",
        (val) => val.length === 2
      ),
    isActive: yup.boolean(),
    recipientCode: yup.string().when("isActive", {
      is: true,
      then: yup
        .string()
        .required("Recipient Code is required when active is on."),
      otherwise: yup.string().notRequired(),
    }),
  });

  const formMethods = useForm({
    resolver: yupResolver(apisSettingsSchema),
    mode: "onSubmit",
    defaultValues: {
      country: "",
      recipientCode: "",
      isActive: false,
    },
  });

  useEffect(() => {
    if (visible) {
      if (mode === "add") {
        formMethods.reset({
          country: "",
          recipientCode: "",
          isActive: false,
        });
      } else {
        formMethods.reset({
          country: editData?.country || "",
          recipientCode: editData?.recipientCode || "",
          isActive: editData?.isActive || false,
        });
      }
    }
  }, [editData, visible, formMethods, mode]);

  const handleFormSubmit = async (formData) => {
    if (mode === "update" && editData) {
      await updateApisSettings({
        id: editData.id,
        country: formData.country,
        recipientCode: formData.recipientCode,
        isActive: formData.isActive,
      });
    } else {
      await addApisSettings({
        country: formData.country,
        recipientCode: formData.recipientCode,
        isActive: formData.isActive,
      });
    }
    toggle(false);
    setEditData(null);
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            {mode.charAt(0).toUpperCase() + mode.slice(1)} Apis Settings
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <TextInput label="Country" name="country" icon={faEarthAsia} />
            </FormGroup>
            <FormGroup>
              <TextInput
                label="Recipient Code"
                name="recipientCode"
                icon={faTag}
              />
            </FormGroup>
            <FormGroup>
              <Controller
                name="isActive"
                control={formMethods.control}
                render={({ field }) => (
                  <Checkbox
                    inputId="isActive"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.checked)}
                    binary={true}
                  />
                )}
              />
              <Label className="form-check-label ms-1" for="isActive">
                Is Active
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ApisSettingModal;

import ApisSettings from ".";

const ApisSettingsRoutes = [
    {
      path: "/apisSettings",
      index: true,
      element: <ApisSettings />
    }
  ];
  
  export default ApisSettingsRoutes;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext, Controller } from "react-hook-form";
import { Chips } from "primereact/chips";
import { classNames } from "primereact/utils";

import { Label, FormFeedback, InputGroup } from "reactstrap";

function ChipsInput({ label, name, color = "primary", icon, ...props }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Label className="form-label" for={name}>
        <h6>{label}</h6>
      </Label>
      <Controller
        id={name}
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <InputGroup>
                {icon && (
                  <span className={`p-inputgroup-addon text-${color}`}>
                    <FontAwesomeIcon icon={icon} />
                  </span>
                )}

                <Chips
                  id={field.name}
                  name={name}
                  value={field.value}
                  style={{ width: "100%" }}
                  separator=","
                  onChange={(e) => field.onChange(e.value)}
                  className={classNames({ "p-invalid": error })}
                />
                {error && <FormFeedback>{error?.message}</FormFeedback>}
                <span class="text-info">
                  <small>Enter mails separated by comma (,)</small>
                </span>
              </InputGroup>
            </>
          );
        }}
      />
    </>
  );
}

export default ChipsInput;

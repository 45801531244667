import React, { Fragment, memo, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  InputGroup,
  Button,
} from "reactstrap";
import {
  useGetApisMessageQuery,
  useExportApisMessageMutation,
} from "@src/redux/messages";
import { useParams } from "react-router-dom";
import { ReadonlyInput } from "@src/components/inputs";
import Loader from "@src/components/shared/Loader";
import {
  faPlane,
  faPlaneDeparture,
  faPlaneArrival,
  faCalendar,
  faFontAwesome,
  faMessage,
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTime } from "@src/utility/Utils";
import { Tag } from "primereact/tag";
import CopyToClipboard from "@src/components/shared/CopyToClipboard";
import ResendMessageModel from "../common/ResendMessageModel";
import { Send, DownloadCloud } from "react-feather";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";

const getMessageSeverity = (status) => {
  switch (status) {
    case "pending":
      return "warning";
    case "sent":
      return "success";
    case "fail":
      return "danger";
    default:
      return "info";
  }
};

const getDirectionIcon = (direction) => {
  switch (direction) {
    case "inbound":
      return faArrowRightToBracket;
    case "outbound":
      return faArrowRightFromBracket;
  }
};

const Details = () => {
  const [exportMessage] = useExportApisMessageMutation();
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const { data, isFetching, error } = useGetApisMessageQuery(id);

  const handleExport = async () => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to export this APIS message?",
      icon: "question",
      confirmButtonText: "Export",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-danger ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        const response = await exportMessage(id).unwrap();
        const blob = new Blob([response], {
          type: "text/plain",
        });
        const fileName = `XY${data.flightNo}_APIS_Message.txt`;

        saveAs(blob, fileName);
        openSweetAlert({
          title: "APIS message exported Successfully!",
        });
      },
    });
  };

  return (
    <Fragment>
      {isFetching && <Loader />}
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Flight Details</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="info-container">
            <br />
            {data ? (
              <>
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Flight Number"
                        name="flightNo"
                        icon={faPlane}
                        defaultValue={data.flightNo}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Departure"
                        name="departure"
                        icon={faPlaneDeparture}
                        defaultValue={data.departure}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Departure Country"
                        name="departureCountry"
                        icon={faPlaneDeparture}
                        defaultValue={data.departureCountry}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="STD UTC"
                        name="stdutc"
                        icon={faCalendar}
                        defaultValue={
                          data.stdutc && formatDateTime(new Date(data.stdutc))
                        }
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1" style={{ visibility: "hidden" }}>
                      <ReadonlyInput label="helper" icon={faPlaneArrival} />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Arrival"
                        name="arrival"
                        icon={faPlaneArrival}
                        defaultValue={data.arrival}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Arrival Country"
                        name="arrivalCountry"
                        icon={faPlaneArrival}
                        defaultValue={data.arrivalCountry}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="STA UTC"
                        name="stautc"
                        icon={faCalendar}
                        defaultValue={
                          data.stautc && formatDateTime(new Date(data.stautc))
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col>
                  <p>{error ? error : "No data available."}</p>
                </Col>
              </Row>
            )}
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">APIS Message Details</CardTitle>
          <div className="flex flex-wrap gap-2">
            <Button.Ripple
              outline
              className="me-1"
              type="button"
              onClick={handleExport}
              color="warning"
            >
              <DownloadCloud size={18} />
              &nbsp; Export
            </Button.Ripple>
            <Button.Ripple
              type="button"
              onClick={() => {
                setVisible(true);
              }}
              color="warning"
            >
              <Send size={18} />
              &nbsp; Resend
            </Button.Ripple>
          </div>
        </CardHeader>
        <CardBody>
          <div className="info-container">
            <br />
            {data && (
              <>
                <Row>
                  <Col lg="4" md="4" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Message Time"
                        name="messageTime"
                        icon={faCalendar}
                        defaultValue={
                          data.messageTime &&
                          formatDateTime(new Date(data.messageTime))
                        }
                      />
                    </div>
                  </Col>
                  <Col lg="4" md="4" className="mb-1">
                    <div className="mb-1">
                      <Label className="form-label" for="flightDirection">
                        <h6>Direction</h6>
                      </Label>
                      <InputGroup>
                        <span className="p-inputgroup-addon text-primary">
                          <FontAwesomeIcon icon={faFontAwesome} />
                        </span>
                        <span
                          className="form-control input-group text-capitalize"
                          style={{ backgroundColor: "#efefef", opacity: 1 }}
                        >
                          {data.flightDirection && (
                            <FontAwesomeIcon
                              icon={getDirectionIcon(data.flightDirection)}
                              className="font-medium-3"
                            />
                          )}{" "}
                          <span style={{ paddingLeft: "5px" }}>
                            {data.flightDirection}
                          </span>
                        </span>
                      </InputGroup>
                    </div>
                  </Col>
                  <Col lg="4" md="4" className="mb-1">
                    <div className="mb-1">
                      <Label className="form-label" for="messageStatus">
                        <h6>Message Status</h6>
                      </Label>
                      <InputGroup>
                        <Tag
                          value={data.status}
                          severity={getMessageSeverity(data.status)}
                          className="text-capitalize"
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={data.status === "sent" ? "6" : "12"}
                    md={data.status === "sent" ? "6" : "12"}
                    className="mb-1"
                  >
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Message Content"
                        name="MessageContent"
                        icon={faMessage}
                        type="textarea"
                        rows="15"
                        defaultValue={data.text}
                      />
                      <CopyToClipboard
                        value={data.text}
                        defaultMsg="Click to copy"
                        successMsg="Copied to clipboard!"
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      {data.status === "sent" && (
                        <div id="ack">
                          <ReadonlyInput
                            label="Ack"
                            name="Ack"
                            icon={faMessage}
                            type="textarea"
                            rows="15"
                            defaultValue={data.ack}
                          />
                          <CopyToClipboard
                            value={data.ack}
                            defaultMsg="Click to copy"
                            successMsg="Copied to clipboard!"
                          ></CopyToClipboard>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </CardBody>
      </Card>
      {data && (
        <ResendMessageModel
          visible={visible}
          toggle={() => setVisible(false)}
          messageId={data.id}
        />
      )}
    </Fragment>
  );
};

export default memo(Details);

import { Dropdown } from "primereact/dropdown";
import {
  faBoxesPacking,
  faUsers,
  faUserTie,
  faEnvelope,
  faNoteSticky,
  faSquareEnvelope,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowsTurnToDots,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";

const messageTypes = ["ldm", "prl", "apis"];

const getMessageType = (type) => {
  switch (type) {
    case "ldm":
      return faBoxesPacking;
    case "prl":
      return faUsers;
    case "apis":
      return faUserTie;
  }
};

const addressTypes = ["sita", "mail", "sitaMail"];

const getAddressType = (addressType) => {
  switch (addressType) {
    case "sita":
      return faNoteSticky;
    case "mail":
      return faEnvelope;
    case "sitaMail":
      return faSquareEnvelope;
  }
};

const messageTypeBodyTemplate = (rowData) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getMessageType(rowData.messageType)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {rowData.messageType}
    </span>
  );
};

const messageTypeFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={messageTypes}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={messageTypeItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const messageTypeItemTemplate = (option) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getMessageType(option)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {option}
    </span>
  );
};

const addressTypeBodyTemplate = (rowData) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getAddressType(rowData.addressType)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {rowData.addressType}
    </span>
  );
};

const addressTypeFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={addressTypes}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={addressTypeItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const addressTypeItemTemplate = (option) => {
  return (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getAddressType(option)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {option}
    </span>
  );
};

const actionsBodyTemplate = (rowData, handleEditClick, handleDeleteClick) => {
  return (
    <>
      <Button
        tooltip="Edit"
        tooltipOptions={{ position: "top" }}
        severity="secondary"
        text
        onClick={() => {
          handleEditClick({ ...rowData });
        }}
      >
        <span className="text-uppercase">
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ fontSize: "1.3rem" }}
          />
        </span>
      </Button>
      <Button
        tooltip="Delete"
        tooltipOptions={{ position: "top" }}
        severity="secondary"
        text
        onClick={() => {
          handleDeleteClick({ ...rowData });
        }}
      >
        <span className="text-uppercase">
          <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: "1.3rem" }} />
        </span>
      </Button>
    </>
  );
};

const messageAddressesColumns = (handleEditClick, handleDeleteClick) => {
  return [
    {
      header: "Country",
      filter: true,
      field: "country",
      sortable: false,
    },
    {
      header: "Station",
      filter: true,
      field: "station",
      sortable: false,
    },
    {
      header: "Address",
      filter: true,
      field: "address",
      sortable: false,
    },
    {
      header: "Flight No",
      filter: true,
      field: "flightNumber",
      sortable: false,
      dataType: "numeric",
    },
    {
      header: "Message Type",
      filter: true,
      field: "messageType",
      sortable: false,
      body: messageTypeBodyTemplate,
      filterElement: messageTypeFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Address Type",
      filter: true,
      field: "addressType",
      sortable: false,
      body: addressTypeBodyTemplate,
      filterElement: addressTypeFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Actions",
      filter: false,
      field: "actions",
      body: (rowData) =>
        actionsBodyTemplate(rowData, handleEditClick, handleDeleteClick),
    },
  ];
};

export default messageAddressesColumns;

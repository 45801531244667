import { useFormContext, Controller } from "react-hook-form";

import { Label, FormFeedback, Row, Col } from "reactstrap";
import { Checkbox } from "primereact/checkbox";

function CheckboxInput({ label, name, ...props }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Row>
      <Col md="4" sm="12">
        <Label className="form-label" for={name}>
          <h6>{label}</h6>
        </Label>
      </Col>
      <Col md="8" sm="12">
        <Controller
          id={name}
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <Checkbox
                  inputId={field.name}
                  id={field.name}
                  invalid={error && true}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  {...props}
                  {...field}
                />
                {error && <FormFeedback>{error?.message}</FormFeedback>}
              </>
            );
          }}
        />
      </Col>
    </Row>
  );
}

export default CheckboxInput;

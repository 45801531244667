import React, { useEffect } from "react";
import CalendarInput from "@src/components/inputs/CalendarInput";
import CheckboxInput from "@src/components/inputs/CheckboxInput";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
  FormFeedback,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { formatDateToString } from "@src/utility/Utils";

const FetchFlightsModel = ({ visible, toggle, fetchFlightsFunc }) => {
  const fetchFlightSchema = yup
    .object()
    .shape({
      start: yup.date().required(),
      end: yup
        .date()
        .required()
        .min(yup.ref("start"), "end Date can't be before start Date")
        .test(
          "max-48-hours",
          "The maximum difference between start and end dates is 48 hours",
          function (value) {
            const { start } = this.parent;
            if (start && value) {
              const diff = Math.abs(new Date(value) - new Date(start));
              const diffHours = diff / (1000 * 60 * 60);
              return diffHours <= 48;
            }
            return true;
          }
        ),
      withPax: yup.boolean(),
      withCrews: yup.boolean(),
    })
    .test(
      "at-least-one-checked",
      'At least one of "Fetch With Pax" or "Fetch With Crews" must be checked',
      function (value) {
        const { withPax, withCrews } = value;
        if (!withPax && !withCrews) {
          return this.createError({
            path: "form",
            message:
              'At least one of "Fetch With Pax" or "Fetch With Crews" must be checked',
          });
        }
        return true;
      }
    );

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        start: "",
        end: "",
        withPax: false,
        withCrews: false,
      });
    }
  }, [visible, formMethods]);

  const formMethods = useForm({
    resolver: yupResolver(fetchFlightSchema),
    mode: "onSubmit",
    defaultValues: {
      start: "",
      end: "",
      withPax: false,
      withCrews: false,
    },
  });

  const handleFormSubmit = async (formData) => {
    await fetchFlightsFunc({
      start: formatDateToString(formData.start),
      end: formatDateToString(formData.end),
      withPax: formData.withPax,
      withCrews: formData.withCrews,
    })
      .unwrap()
      .then(() => {
        openSweetAlert({
          title: "Flights fetched successfully!",
        });
      });
    toggle(false);
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Fetch Flights</ModalHeader>
          <ModalBody>
            <FormGroup>
              <CalendarInput
                label="start Date"
                name="start"
                icon={faCalendar}
                options={{
                  enableTime: true,
                }}
              />
            </FormGroup>
            <FormGroup>
              <CalendarInput
                label="end Date"
                name="end"
                icon={faCalendar}
                options={{
                  enableTime: true,
                }}
              />
            </FormGroup>
            <FormGroup>
              <CheckboxInput label="Fetch With Pax" name="withPax" />
              <CheckboxInput label="Fetch With Crew" name="withCrews" />
              {formMethods.formState.errors.form && (
                <FormFeedback className="d-block">
                  {formMethods.formState.errors.form.message}
                </FormFeedback>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Fetch
            </Button>
            <Button type="button" color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default FetchFlightsModel;

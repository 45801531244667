import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileCode,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const LoadSheetsColumns = () => {
  const types = ["html", "xml", "pdf"];

  const getTypeIcon = (type) => {
    switch (type) {
      case "html":
        return faFileCode;
      case "xml":
        return faFileCode;
      case "pdf":
        return faFilePdf;
    }
  };
  const IsUploadedStatuses = [
    { label: "Uploaded", value: "true" },
    { label: "Not Uploaded", value: "false" },
  ];

  const formatDate = (value) => {
    return value.toLocaleDateString("en-UK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const typeBodyTemplate = (rowData) => {
    return (
      <span className="text-uppercase">
        <FontAwesomeIcon
          icon={getTypeIcon(rowData.type)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {rowData.type}
      </span>
    );
  };

  const typeItemTemplate = (option) => {
    return (
      <span className="text-uppercase">
        <FontAwesomeIcon
          icon={getTypeIcon(option)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {option}
      </span>
    );
  };

  const typeFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={types}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={typeItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const flightTimeBodyTemplate = (rowData) => {
    return formatDate(new Date(rowData.flightTime));
  };

  const flightTimeFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value ? new Date(options.value) : null}
        onChange={(e) => {
          options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index);
        }}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const actionsBodyTemplate = (row) => {
    return (
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <Link to={`/sita-messages/load-sheets/${row.id}`}>
          {" "}
          <Eye className="font-medium-3 text-body" />
        </Link>
      </div>
    );
  };

  const getIsUploadedStatusIcon = (isUploaded) =>
    isUploaded === "true" ? faCircleCheck : faCircleXmark;

  const getIsUploadedStatusClass = (isUploaded) =>
    isUploaded === "true" ? "text-success" : "text-danger";

  const IsUploadedStatusBodyTemplate = ({ isUploaded }) => (
    <span className="text-uppercase">
      <FontAwesomeIcon
        icon={getIsUploadedStatusIcon(isUploaded.toString())}
        className={getIsUploadedStatusClass(isUploaded.toString())}
        style={{ fontSize: "1.3rem" }}
        aria-hidden="true"
      />
      <span className="sr-only">
        {isUploaded === "true" ? "Uploaded" : "NotUploaded"}
      </span>
    </span>
  );

  const IsUploadedStatusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={IsUploadedStatuses}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={IsUploadedStatusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const IsUploadedStatusItemTemplate = ({ label, value }) => {
    return (
      <span>
        <FontAwesomeIcon
          icon={getIsUploadedStatusIcon(value)}
          className={getIsUploadedStatusClass(value)}
          style={{ fontSize: "1.3rem" }}
        />{" "}
        {label}
      </span>
    );
  };

  return [
    {
      header: "Subject",
      filter: true,
      field: "subject",
      sortable: false,
    },
    {
      header: "Flight No.",
      filter: true,
      field: "flightNo",
      sortable: false,
      dataType: "numeric",
    },
    {
      header: "Departure",
      filter: true,
      field: "departureIcao",
      sortable: false,
    },
    {
      header: "Arrival",
      filter: true,
      field: "arrivalIcao",
      sortable: false,
    },
    {
      header: "Registration",
      filter: true,
      field: "registration",
      sortable: false,
    },
    {
      header: "Flight Time",
      filter: true,
      field: "flightTime",
      sortable: false,
      dataType: "date",
      filterField: "flightTime.date",
      body: flightTimeBodyTemplate,
      filterElement: flightTimeFilterTemplate,
    },
    {
      header: "Type",
      filter: true,
      field: "type",
      sortable: false,
      body: typeBodyTemplate,
      filterElement: typeFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Is Uploaded",
      filter: true,
      field: "isUploaded",
      sortable: false,
      filterField: "isUploaded",
      body: IsUploadedStatusBodyTemplate,
      filterElement: IsUploadedStatusFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Actions",
      field: "action",
      filter: false,
      body: actionsBodyTemplate,
    },
  ];
};

export default LoadSheetsColumns;

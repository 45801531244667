import { mainApi } from "./mainApi";

export const loadSheetsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getLoadSheets: builder.query({
      query: (filters) => ({
        url: `/loadsheets/search`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "Loadsheets", id: "LIST" }],
    }),
    getLoadSheet: builder.query({
      query: (id) => ({
        url: `/loadsheets/${id}`,
        method: "GET",
      }),
    }),
    matchFlightsWithLoadSheets: builder.mutation({
      query: () => ({
        url: `/loadsheets/match-flights`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Loadsheets", id: "LIST" }],
    }),
    uploadLoadSheet: builder.mutation({
      query: (duration) => ({
        url: `/loadsheets/upload`,
        method: "POST",
        body: duration,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLoadSheetsQuery,
  useGetLoadSheetQuery,
  useMatchFlightsWithLoadSheetsMutation,
  useUploadLoadSheetMutation,
} = loadSheetsApi;

import React, { useState } from "react";
import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetMessageAddressesQuery,
  useDeleteMessageAddressMutation,
} from "@src/redux/messageAddresses";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import messageAddressesColumns from "./Columns";
import MessageAddressModal from "./MessageAddressModal";
import openSweetAlert from "@src/components/shared/OpenSweetAlert";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";

const MessageAddresses = () => {
  const [editData, setEditData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState("add");

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    country: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    station: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    address: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    flightNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    messageType: { value: null, matchMode: FilterMatchMode.EQUALS },
    addressType: { value: null, matchMode: FilterMatchMode.EQUALS },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading } = useGetMessageAddressesQuery(filters);
  const [deleteMessageAddress, { isLoading: loadingDeleteMessageAddress }] =
    useDeleteMessageAddressMutation();
  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };
  const handleAddClick = () => {
    setEditData(null);
    setMode("add");
    setVisible(true);
  };

  const handleEditClick = (item) => {
    setEditData(item);
    setMode("update");
    setVisible(true);
  };

  const handleDeleteClick = async (item) => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Delete this message address?",
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-danger ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await deleteMessageAddress(item.id)
          .unwrap()
          .then(() => {
            openSweetAlert({
              title: "Message address deleted Successfully!",
            });
          });
      },
    });
  };

  return (
    <>
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={messageAddressesColumns(
              handleEditClick,
              handleDeleteClick
            )}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Message Address Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            displayAdd={true}
            handleAdd={handleAddClick}
            tableState={filters}
          />
        </CardBody>
      </Card>
      <MessageAddressModal
        visible={visible}
        toggle={() => setVisible(false)}
        editData={editData}
        setEditData={setEditData}
        mode={mode}
      />
    </>
  );
};

export default MessageAddresses;

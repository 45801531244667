import LdmMessages from "."
import Details from "./details"

const LdmMessagesRoutes = [
    {
      path: "/ldm-messages",
      index: true,
      element: <LdmMessages />
    },
    {
        path: "/ldm-messages/:id",
        index: true,
        element: <Details />
    }
  ]
  
  export default LdmMessagesRoutes
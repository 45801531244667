import React, { Fragment, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  InputGroup,
} from "reactstrap";
import { useGetNotificationQuery } from "@src/redux/notifications";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ReadonlyInput } from "@src/components/inputs";
import Loader from "@src/components/shared/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeCircleCheck,
  faCircleExclamation,
  faBell,
  faForward,
  faCalendar,
  faMessage,
  faHeading,
  faLink,
  faFontAwesome,
} from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "@src/components/copyToClipboard";
import { Eye } from "react-feather";
import { formatDateTime } from "@src/utility/Utils";

const getTypeText = (type) => {
  switch (type) {
    case "messageSent":
      return "Message Sent";
    case "messageFailed":
      return "Message Failed";
    case "loadSheetReminder":
      return "LoadSheet Reminder";
    case "loadSheetForwarded":
      return "LoadSheet Forwarded";
  }
};

const getTypeIcon = (type) => {
  switch (type) {
    case "messageSent":
      return faEnvelopeCircleCheck;
    case "messageFailed":
      return faCircleExclamation;
    case "loadSheetReminder":
      return faBell;
    case "loadSheetForwarded":
      return faForward;
  }
};

const getReference = (type) => {
  switch (type) {
    case "messageSent":
      return "Message";
    case "messageFailed":
      return "Message";
    case "loadSheetReminder":
      return "Flight";
    case "loadSheetForwarded":
      return "Loadsheet";
    default:
      return "";
  }
};

const Details = () => {
  const { id } = useParams();
  const { data, isFetching, error } = useGetNotificationQuery(id);
  const navigate = useNavigate();

  const navigateToReference = (e) => {
    e.preventDefault();
    switch (data.type.toLowerCase()) {
      case "messagesent":
        if (data.referenceId) {
          navigate(`/sita-messages/ldm-messages/${data.referenceId}`);
        }
        break;
      case "messagefailed":
        if (data.referenceId) {
          navigate(`/sita-messages/ldm-messages/${data.referenceId}`);
        }
        break;
      case "loadsheetreminder":
        if (data.referenceId) {
          navigate(`/sita-messages/flights/${data.referenceId}`);
        }
        break;
      case "loadsheetforwarded":
        if (data.referenceId) {
          navigate(`/sita-messages/load-sheets/${data.referenceId}`);
        }
        break;
      default:
    }
  };

  return (
    <Fragment>
      {isFetching && <Loader />}
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Notification Details</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="info-container">
            <br />
            {data ? (
              <>
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Subject"
                        name="subject"
                        icon={faHeading}
                        defaultValue={data.subject}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Recipient"
                        name="to"
                        icon={faHeading}
                        defaultValue={data.to}
                      />
                    </div>
                    <div className="mb-1">
                      <Label className="form-label" for="referenceId">
                        <h6>{getReference(data?.type)}</h6>
                      </Label>
                      <InputGroup>
                        <span className="p-inputgroup-addon text-primary">
                          <FontAwesomeIcon icon={faLink} />
                        </span>
                        <span
                          className="form-control input-group"
                          style={{ backgroundColor: "#efefef", opacity: 1 }}
                        >
                          <Link
                            to="#"
                            onClick={navigateToReference}
                            title="Click here to view Reference Detail."
                          >
                            {getReference(data?.type)}{" "}
                            <Eye className="font-medium-3 text-body mx-1" />
                          </Link>
                        </span>
                      </InputGroup>
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <Label className="form-label" for="type">
                        <h6>Notification Type</h6>
                      </Label>
                      <InputGroup>
                        <span className="p-inputgroup-addon text-primary">
                          <FontAwesomeIcon icon={faFontAwesome} />
                        </span>
                        <span
                          className="form-control input-group"
                          style={{ backgroundColor: "#efefef", opacity: 1 }}
                        >
                          <FontAwesomeIcon
                            icon={getTypeIcon(data.type)}
                            style={{ fontSize: "1.3rem", marginRight: "5px" }}
                          />{" "}
                          {getTypeText(data.type)}
                        </span>
                      </InputGroup>
                      <p className="form-control-static" id="type"></p>
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Notification Time"
                        name="notifyTime"
                        icon={faCalendar}
                        defaultValue={
                          data.notifyTime &&
                          formatDateTime(new Date(data.notifyTime))
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Notification Content"
                        name="text"
                        icon={faMessage}
                        type="textarea"
                        rows="5"
                        defaultValue={data.text}
                      />
                      <CopyToClipboard
                        value={data.text}
                        defaultMsg="Click to copy"
                        successMsg="Copied to clipboard!"
                      ></CopyToClipboard>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col>
                  <p>{error ? error : "No data available."}</p>
                </Col>
              </Row>
            )}
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default memo(Details);

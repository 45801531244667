import React, { Fragment, memo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  InputGroup,
} from "reactstrap";
import { useGetLoadSheetQuery } from "@src/redux/loadsheets";
import { useParams } from "react-router-dom";
import { ReadonlyInput } from "@src/components/inputs";
import Loader from "@src/components/shared/Loader";
import {
  faHeading,
  faPlane,
  faPlaneDeparture,
  faPlaneArrival,
  faIdCard,
  faCalendar,
  faWeightScale,
  faPeopleGroup,
  faFilePdf,
  faFileCode,
  faFontAwesome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import XMLViewer from "react-xml-viewer";
import { formatDateTime } from "@src/utility/Utils";
import { Tag } from "primereact/tag";

const getTypeIcon = (type) => {
  switch (type) {
    case "html":
      return faFileCode;
    case "xml":
      return faFileCode;
    case "pdf":
      return faFilePdf;
  }
};

const setSeverity = (value) => (value ? "success" : "danger");

const Details = () => {
  const { id } = useParams();
  const { data, isFetching, error } = useGetLoadSheetQuery(id);
  document.body.style.fontSize = "1rem";
  return (
    <Fragment>
      {isFetching && <Loader />}
      <div>
        <Card>
          <CardHeader className="border-bottom">
            <CardTitle tag="h4">Load-Sheet Details</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="info-container">
              <br />
              {data ? (
                <Row>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Mail Subject"
                        name="subject"
                        icon={faHeading}
                        defaultValue={data.subject}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Flight Number"
                        name="flightNo"
                        icon={faPlane}
                        defaultValue={data.flightNo}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Departure"
                        name="departureIcao"
                        icon={faPlaneDeparture}
                        defaultValue={data.departureIcao}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Arrival"
                        name="arrivalIcao"
                        icon={faPlaneArrival}
                        defaultValue={data.arrivalIcao}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Registration"
                        name="registration"
                        icon={faIdCard}
                        defaultValue={data.registration}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Flight Time"
                        name="flightTime"
                        icon={faCalendar}
                        defaultValue={
                          data.flightTime &&
                          formatDateTime(new Date(data.flightTime))
                        }
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Received at"
                        name="receivedAt"
                        icon={faCalendar}
                        defaultValue={
                          data.receivedAt &&
                          formatDateTime(new Date(data.receivedAt))
                        }
                      />
                    </div>
                    <div className="mb-1">
                      <Label className="form-label" for="type">
                        <h6>Loadsheet-Type</h6>
                      </Label>
                      <InputGroup>
                        <span className="p-inputgroup-addon text-primary">
                          <FontAwesomeIcon icon={faFontAwesome} />
                        </span>
                        <span
                          className="form-control input-group text-uppercase"
                          style={{ backgroundColor: "#efefef", opacity: 1 }}
                        >
                          <FontAwesomeIcon
                            icon={getTypeIcon(data.type)}
                            className="font-medium-3 text-body"
                          />{" "}
                          {data.type}
                        </span>
                      </InputGroup>
                    </div>
                    <div className="mb-1">
                      <Label className="form-label" for="isUploaded">
                        <h6>Is Uploaded</h6>
                      </Label>
                      <p className="form-control-static" id="isUploaded">
                        <Tag
                          value={data.isUploaded ? "Yes" : "No"}
                          className="text-capitalize"
                          severity={setSeverity(data.isUploaded)}
                        >
                          {" "}
                        </Tag>
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Cabin Crew"
                        name="cabinCrew"
                        icon={faPeopleGroup}
                        defaultValue={data.cabinCrew}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Cockpit Crew"
                        name="CockpitCrew"
                        icon={faPeopleGroup}
                        defaultValue={data.cockpitCrew}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Compartment 1"
                        name="compartment1"
                        icon={faWeightScale}
                        defaultValue={data.compartment1}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Compartment 2"
                        name="compartment2"
                        icon={faWeightScale}
                        defaultValue={data.compartment2}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Compartment 3"
                        name="compartment3"
                        icon={faWeightScale}
                        defaultValue={data.compartment3}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Compartment 4"
                        name="compartment4"
                        icon={faWeightScale}
                        defaultValue={data.compartment4}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Compartment 5"
                        name="compartment5"
                        icon={faWeightScale}
                        defaultValue={data.compartment5}
                      />
                    </div>
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Total Weight"
                        name="weight"
                        icon={faWeightScale}
                        defaultValue={data.weight}
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <p>{error ? error : "No data available."}</p>
                  </Col>
                </Row>
              )}
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className="border-bottom">
            <CardTitle tag="h4">Load-Sheet File</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="info-container">
              <br />
              {data && data.content && (
                <Row>
                  <Col className="col-12 mb-1">
                    <div id="content" className="attachedFileContent">
                      {data.type === "xml" && <XMLViewer xml={data.content} />}
                      {data.type === "html" && (
                        <span
                          dangerouslySetInnerHTML={{ __html: data.content }}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};

export default memo(Details);

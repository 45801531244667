import MessageAddresses from ".";

const MessageAddressesRoutes = [
    {
      path: "/messageAddresses",
      index: true,
      element: <MessageAddresses />
    }
  ];
  
  export default MessageAddressesRoutes;
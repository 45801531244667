import PrlMessages from ".";
import Details from "./details";

const PrlMessagesRoutes = [
  {
    path: "/prl-messages",
    index: true,
    element: <PrlMessages />,
  },
  {
    path: "/prl-messages/:flightId/:partNo",
    index: true,
    element: <Details />,
  },
];

export default PrlMessagesRoutes;

import { Fragment, memo, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  Table,
  Label,
  Alert,
} from "reactstrap";
import {
  useGetPrlMessageQuery,
  useExportPrlMessageMutation,
} from "@src/redux/messages";
import { useParams } from "react-router-dom";
import CopyToClipboard from "@src/components/copyToClipboard";
import { ReadonlyInput } from "@src/components/inputs";
import {
  faPlane,
  faCalendar,
  faMessage,
  faHourglassHalf,
  faEnvelopeCircleCheck,
  faCircleExclamation,
  faHashtag,
  faUsers,
  faPlaneDeparture,
  faPlaneArrival,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "@src/components/shared/Loader";
import { TabView, TabPanel } from "primereact/tabview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTime } from "@src/utility/Utils";
import ResendMessageModel from "./ResendMessageModel";
import { Send, Eye, AlertCircle } from "react-feather";
import IconButton from "@src/components/shared/IconButton";
import MessageHistoryModel from "../common/MessageHistoryModel";
import ErrorModal from "./ErrorModal";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import ExportComponent from "../common/ExportComponent";
import StatusTag from "../../components/Messages/StatusTag";

const getSeverityIcon = (status) => {
  switch (status) {
    case "pending":
      return faHourglassHalf;
    case "sent":
      return faEnvelopeCircleCheck;
    case "fail":
      return faCircleExclamation;
    default:
      return faMessage;
  }
};

const getSeverityClass = (status) => {
  switch (status) {
    case "pending":
      return "text-warning";
    case "sent":
      return "text-success";
    case "fail":
      return "text-danger";
    default:
      return "text-info";
  }
};

const Details = () => {
  const [currentItem, setCurrentItem] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [exportMessage] = useExportPrlMessageMutation();
  const [visible, setVisible] = useState(false);
  const { flightId, partNo } = useParams();
  const { data, isFetching } = useGetPrlMessageQuery(flightId);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [selectedErrors, setSelectedErrors] = useState([]);

  const handleShowDetails = (item) => {
    console.log(item);
    setCurrentItem(item);
    setShowDetails(true);
  };

  useEffect(() => {
    if (partNo && data && data.messageParts.length >= partNo) {
      setActiveIndex(parseInt(partNo) - 1);
    }
  }, [partNo, data]);

  const toggleErrorModal = () => setIsErrorModalOpen(!isErrorModalOpen);

  const showErrors = (errors) => {
    setSelectedErrors(errors);
    toggleErrorModal();
  };

  const tabHeaderTemplate = (part, options) => {
    const severityClass = getSeverityClass(part.status);
    return (
      <div
        className={`flex align-items-center gap-2 p-1 ${severityClass}`}
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
        key={"part" + part.partNo}
      >
        <FontAwesomeIcon
          icon={getSeverityIcon(part.status)}
          style={{ fontSize: "0.8rem" }}
        />
        <span className="text-uppercase font-bold white-space-nowrap">
          {" "}
          Part-{part.partNo}
        </span>
      </div>
    );
  };

  return (
    <Fragment>
      {isFetching && <Loader />}
      <BreadCrumbs pageName="prlDetails" pageTitle="PRL Message Details" />
      {data && (
        <>
          <Row>
            <Col lg="8" md="8" xs="8">
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle tag="h1" className="fw-bolder">
                    <strong>
                      <span className="text-primary">
                        <FontAwesomeIcon icon={faPlane} />
                      </span>{" "}
                      <span className="text-primary">Flight</span>
                      {"  "}
                      <span class="fw-bolder mb-25">
                        {" "}
                        XY{data.flightNo}
                      </span>{" "}
                      <span class="font-small-2 text-muted">Flight No</span>
                    </strong>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="info-container">
                    <br />
                    <Row>
                      <div className="info-container">
                        <Row>
                          <Col xl="6" xs="12">
                            <Row tag="dl" className="mb-0">
                              <Col tag="dt" sm="4" className="fw-bolder mb-1">
                                Departure:
                              </Col>
                              <Col tag="dd" sm="7" className="mb-1">
                                <span className="text-primary">
                                  &nbsp;
                                  <FontAwesomeIcon icon={faPlaneDeparture} />
                                </span>{" "}
                                {data.departure}
                              </Col>

                              <Col tag="dt" sm="4" className="fw-bolder mb-1">
                                STD:
                              </Col>
                              <Col tag="dd" sm="7" className="mb-1">
                                {data.std && formatDateTime(new Date(data.std))}
                              </Col>

                              <Col tag="dt" sm="4" className="fw-bolder mb-1">
                                STD{" "}
                                <span class="font-small-2 text-muted">
                                  (UTC)
                                </span>
                                :
                              </Col>
                              <Col tag="dd" sm="7" className="mb-1">
                                {data.stdutc &&
                                  formatDateTime(new Date(data.stdutc))}
                              </Col>
                            </Row>
                          </Col>
                          <Col xl="6" xs="12" className="mb-1">
                            <Row tag="dl" className="mb-0">
                              <Col tag="dt" sm="4" className="fw-bolder mb-1">
                                Arrival:
                              </Col>
                              <Col tag="dd" sm="7" className="mb-1">
                                <span className="text-primary">
                                  &nbsp;
                                  <FontAwesomeIcon icon={faPlaneArrival} />
                                </span>{" "}
                                {data.arrival}
                              </Col>

                              <Col tag="dt" sm="4" className="fw-bolder mb-1">
                                STA:
                              </Col>
                              <Col tag="dd" sm="7" className="mb-1">
                                {data.sta && formatDateTime(new Date(data.sta))}
                              </Col>
                              <Col tag="dt" sm="4" className="fw-bolder mb-1">
                                STA{" "}
                                <span class="font-small-2 text-muted">
                                  (UTC)
                                </span>
                                :
                              </Col>
                              <Col tag="dd" sm="7" className="mb-1">
                                {data.sta &&
                                  formatDateTime(new Date(data.stautc))}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4" md="4" xs="4">
              <Card>
                <CardHeader className="border-bottom">
                  <CardTitle tag="h1" className="fw-bolder">
                    <strong>
                      <span className="text-primary">
                        <FontAwesomeIcon icon={faUsers} />
                      </span>{" "}
                      <span className="text-primary">PRL</span>
                      {"  "}
                      <span class="fw-bolder mb-25">Message Details</span>
                    </strong>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="info-container">
                    <br />
                    {data ? (
                      <>
                        <Row>
                          <Col xl="12" xs="12" className="mb-1">
                            <Row tag="dl" className="mb-0">
                              <Col tag="dt" sm="6" className="fw-bolder mb-1">
                                Total Inbound Pax Count:
                              </Col>
                              <Col tag="dd" sm="6" className="mb-1">
                                {data.inboundPassengersCount}
                              </Col>

                              <Col tag="dt" sm="6" className="fw-bolder mb-1">
                                Total Onward Pax Count:
                              </Col>
                              <Col tag="dd" sm="6" className="mb-1">
                                {data.onwardPassengersCount}
                              </Col>
                            </Row>
                          </Col>
                          <Col lg="12" md="12">
                            <Row>
                              <Col xl="6" xs="6" className="d-grid">
                                <ExportComponent
                                  id={data.id}
                                  exportMessageFunction={exportMessage}
                                  messageStatus={
                                    data.messageParts.some(
                                      (part) => part.status === "sent"
                                    )
                                      ? "sent"
                                      : undefined
                                  }
                                  exportFileName={`XY${data.flightNo}_PRL_Message.txt`}
                                />
                              </Col>
                              <Col xl="6" xs="6" className="d-grid">
                                <Button.Ripple
                                  type="button"
                                  onClick={() => {
                                    setVisible(true);
                                  }}
                                  color="secondary"
                                >
                                  <Send size={18} />
                                  &nbsp; Resend
                                </Button.Ripple>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <Col>
                          <p>{error ? error : "No data available."}</p>
                        </Col>
                      </Row>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardHeader className="border-bottom">
              <CardTitle tag="h1" className="fw-bolder">
                <strong>
                  <span className="text-primary">
                    <FontAwesomeIcon icon={faPuzzlePiece} />
                  </span>{" "}
                  <span className="text-primary">PRL</span>
                  {"  "}
                  <span class="fw-bolder mb-25">Message Parts</span>
                </strong>
              </CardTitle>
              <div className="d-flex justify-content-center">
                <div className="ms-1">
                  <span class="bullet bullet-sm bullet-warning me-1"></span>
                  Pending
                </div>
                <div className="ms-1">
                  <span class="bullet bullet-sm bullet-danger me-1"></span>
                  Fail
                </div>
                <div className="ms-1">
                  <span class="bullet bullet-sm bullet-success me-1"></span>
                  Success
                </div>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <div className="info-container">
                <TabView
                  scrollable
                  activeIndex={activeIndex}
                  onTabChange={(e) => setActiveIndex(e.index)}
                >
                  {data.messageParts.map((part, index) => (
                    <TabPanel
                      rightIcon="fa-solid fa-building"
                      leftIcon="pi pi-calendar mr-2"
                      header={`Part ${index + 1}`}
                      headerTemplate={(options) =>
                        tabHeaderTemplate(part, options)
                      }
                      key={index}
                    >
                      <Row>
                        <Col lg="3" md="3" className="mb-1">
                          <ReadonlyInput
                            label="Message Time"
                            name="MessageTime"
                            icon={faCalendar}
                            defaultValue={
                              part.messageTime &&
                              formatDateTime(new Date(part.messageTime))
                            }
                          />
                        </Col>
                        <Col lg="3" md="3" className="mb-1">
                          {" "}
                          <div className="mb-1">
                            <ReadonlyInput
                              label="Inbound Pax Count"
                              name="inboundPassengersCount"
                              icon={faHashtag}
                              defaultValue={part.inboundPassengersCount}
                            />
                          </div>
                        </Col>
                        <Col lg="3" md="3" className="mb-1">
                          <div className="mb-1">
                            <ReadonlyInput
                              label="Onward Pax Count"
                              name="onwardPassengersCount"
                              icon={faHashtag}
                              defaultValue={part.onwardPassengersCount}
                            />
                          </div>
                        </Col>
                        <Col lg="3" md="3" className="mb-1">
                          {part.status === "fail" &&
                            part.messageErrors?.length > 0 && (
                              <div>
                                <Label
                                  className="form-label"
                                  style={{ visibility: "hidden" }}
                                >
                                  <h6>Errors </h6>
                                </Label>
                                <br></br>
                                <Button
                                  color="danger"
                                  onClick={() => showErrors(part.messageErrors)}
                                >
                                  {`View (${
                                    part.messageErrors?.length ?? 0
                                  }) Error`}
                                </Button>
                              </div>
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={part.status === "sent" ? "6" : "12"}
                          md={part.status === "sent" ? "6" : "12"}
                          className="mb-1"
                        >
                          <div className="mb-1">
                            <ReadonlyInput
                              label="Message Content"
                              name="MessageContent"
                              icon={faMessage}
                              type="textarea"
                              rows="15"
                              defaultValue={part.text}
                            />
                            <CopyToClipboard
                              value={part.text}
                              defaultMsg="Click to copy"
                              successMsg="Copied to clipboard!"
                            />
                          </div>
                        </Col>
                        <Col lg="6" md="6" className="mb-1">
                          <div className="mb-1">
                            {part.status === "sent" && (
                              <div id="ack">
                                <ReadonlyInput
                                  label="Ack"
                                  name="Ack"
                                  icon={faMessage}
                                  type="textarea"
                                  rows="15"
                                  defaultValue={part.ack}
                                />
                                <CopyToClipboard
                                  value={part.ack}
                                  defaultMsg="Click to copy"
                                  successMsg="Copied to clipboard!"
                                ></CopyToClipboard>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <h5 class="mb-1 fw-bolder">
                            Message History (Part {`#${part.partNo}`})
                          </h5>
                          {part.messageHistories?.length > 0 ? (
                            <Table bordered responsive className="mb-2">
                              <thead>
                                <tr>
                                  <th className="text-capitalize">#</th>
                                  <th className="text-capitalize">
                                    Message Time
                                  </th>
                                  <th className="text-capitalize">
                                    Sending Mode
                                  </th>
                                  <th className="text-capitalize">Status</th>
                                  <th className="text-capitalize">
                                    Address Types
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {part.messageHistories.map((item, index) => {
                                  const primaryAddress =
                                    item.historyAddresses.find(
                                      (address) => address.primary
                                    );
                                  return (
                                    <tr key={`${part.partNo}-${index}`}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {item.messageTime &&
                                          formatDateTime(
                                            new Date(item.messageTime)
                                          )}
                                      </td>
                                      <td className="text-capitalize">
                                        {item.sendingMode}
                                      </td>
                                      <td>
                                        <StatusTag
                                          status={primaryAddress.status}
                                        />
                                      </td>
                                      <td>
                                        {[
                                          ...new Set(
                                            item.historyAddresses.map(
                                              (address) => address.addressType
                                            )
                                          ),
                                        ].join(", ")}
                                      </td>
                                      <td>
                                        <div className="fw-bolder text-success">
                                          <IconButton
                                            icon={
                                              <Eye
                                                className="font-medium-3 text-body"
                                                title="Show"
                                                style={{ marginLeft: 10 }}
                                              />
                                            }
                                            tooltip={"Show details"}
                                            tooltipOptions={{
                                              position: "left",
                                            }}
                                            onClick={() =>
                                              handleShowDetails(item)
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          ) : (
                            <Alert color="dark">
                              <div className="alert-body mt-1">
                                <AlertCircle size={14} />
                                <span className="align-middle ms-50">
                                  No history found for this message.
                                </span>
                              </div>
                            </Alert>
                          )}
                        </Col>
                      </Row>
                    </TabPanel>
                  ))}
                </TabView>
              </div>
            </CardBody>
          </Card>
        </>
      )}
      {data && (
        <>
          <ResendMessageModel
            visible={visible}
            toggle={() => setVisible(false)}
            flightId={flightId}
            messageParts={data.messageParts}
            selectedMessagePart={partNo}
          />
          <ErrorModal
            isOpen={isErrorModalOpen}
            toggle={toggleErrorModal}
            errors={selectedErrors}
          />
        </>
      )}
      {currentItem && (
        <MessageHistoryModel
          visible={showDetails}
          toggle={() => setShowDetails(false)}
          history={currentItem}
          partNo={currentItem.partNo}
        />
      )}
    </Fragment>
  );
};

export default memo(Details);

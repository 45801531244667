import Notifications from "."
import Details from "../notifications/details"

const NotificationsRoutes = [
    {
        path: "/notifications",
        index: true,
        element: <Notifications />
    },
    {
        path: "/notifications/:id",
        index: true,
        element: <Details />
    }
]

export default NotificationsRoutes
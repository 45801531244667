import MessageSenders from ".";

const MessageSendersRoutes = [
    {
      path: "/messageSenders",
      index: true,
      element: <MessageSenders />
    }
  ];
  
  export default MessageSendersRoutes;
import LoadSheets from ".";
import Details from "../loadSheets/details";
import Upload from "./upload";

const LoadSheetsRoutes = [
  {
    path: "/load-sheets",
    index: true,
    element: <LoadSheets />,
  },
  {
    path: "/load-sheets/:id",
    index: true,
    element: <Details />,
  },
  {
    path: "/load-sheets/upload",
    index: true,
    element: <Upload />,
  },
];

export default LoadSheetsRoutes;

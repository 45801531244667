import ApisMessages from "."
import Details from "./details"

const ApisMessagesRoutes = [
    {
        path: "/apis-messages",
        index: true,
        element: <ApisMessages />
    },
    {
        path: "/apis-messages/:id",
        index: true,
        element: <Details />
    }
]

export default ApisMessagesRoutes
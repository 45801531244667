import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { formatDateTime } from "@src/utility/Utils";
import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import StatusTag from "../../components/Messages/StatusTag";

const messageStatuses = ["pending", "sent", "fail"];

const statusBodyTemplate = (rowData) => {
  return <StatusTag status={rowData.status} />;
};

const statusItemTemplate = (option) => {
  return <StatusTag status={option} />;
};

const statusFilterTemplate = (options) => {
  return (
    <Dropdown
      value={options.value}
      options={messageStatuses}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={statusItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );
};

const dateFilterTemplate = (options) => {
  return (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="mm/dd/yy"
      placeholder="mm/dd/yyyy"
      mask="99/99/9999"
    />
  );
};

const messageTimeBodyTemplate = (rowData) => {
  return formatDateTime(new Date(rowData.messageTime));
};

const actionsBodyTemplate = (row) => {
  return (
    <div className="d-flex" style={{ justifyContent: "space-between" }}>
      <Link to={`/sita-messages/ldm-messages/${row.id}`}>
        {" "}
        <Eye className="font-medium-3 text-body" />
      </Link>
    </div>
  );
};

export const ldmMessagesColumns = [
  {
    header: "Flight No",
    filter: true,
    field: "flightNo",
    sortable: false,
    filterField: "flight.flightNo",
    dataType: "numeric",
  },
  {
    header: "Departure",
    filter: true,
    field: "departure",
    sortable: false,
    filterField: "flight.departure",
  },
  {
    header: "Arrival",
    filter: true,
    field: "arrival",
    sortable: false,
    filterField: "flight.arrival",
  },
  {
    header: "Message Time",
    filter: true,
    field: "messageTime",
    sortable: false,
    dataType: "date",
    body: messageTimeBodyTemplate,
    filterElement: dateFilterTemplate,
  },
  {
    header: "Message Status",
    filter: true,
    field: "status",
    sortable: false,
    body: statusBodyTemplate,
    filterElement: statusFilterTemplate,
    showFilterMatchModes: false,
  },
  {
    header: "Actions",
    field: "action",
    filter: false,
    body: actionsBodyTemplate,
  },
];

import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Form,
  Label,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import * as yup from "yup";
import {
  useAddStationMailMutation,
  useUpdateStationMailMutation,
} from "@src/redux/stationmails";
import { faPlaneDeparture, faComment } from "@fortawesome/free-solid-svg-icons";
import { TextInput, ChipsInput } from "@src/components/inputs";
import { Checkbox } from "primereact/checkbox";

const StationMailsModal = ({
  visible,
  toggle,
  editData,
  setEditData,
  mode,
}) => {
  const [updateStationMail] = useUpdateStationMailMutation();
  const [addStationMail] = useAddStationMailMutation();

  const messageSenderSchema = yup.object().shape({
    station: yup
      .string()
      .nullable()
      .test(
        "len",
        "Station must be 3 characters in length",
        (val) => val.length === 3
      ),
    icao: yup
      .string()
      .nullable()
      .test(
        "len",
        "ICAO must be 4 characters in length",
        (val) => val.length === 4 || val.length === 0
      ),
    mails: yup.array().of(yup.string()).min(1, "mails is required"),
  });

  const formMethods = useForm({
    resolver: yupResolver(messageSenderSchema),
    mode: "onSubmit",
    defaultValues: {
      station: "",
      icao: "",
      mails: "",
      comments: "",
      active: false,
    },
  });

  useEffect(() => {
    if (visible) {
      if (mode === "add") {
        formMethods.reset({
          station: "",
          icao: "",
          mails: "",
          comments: "",
          active: false,
        });
      } else {
        formMethods.reset({
          station: editData?.station || "",
          icao: editData?.icao || "",
          comments: editData?.comments || "",
          mails: editData?.mails ? editData?.mails.split(";") : [],
          active: editData?.active || false,
        });
      }
    }
  }, [editData, visible, formMethods, mode]);

  const handleFormSubmit = async (formData) => {
    if (mode === "update" && editData) {
      await updateStationMail({
        id: editData.id,
        station: formData.station,
        icao: formData.icao,
        mails: formData.mails.join(";"),
        comments: formData.comments,
        active: formData.active,
      });
    } else {
      await addStationMail({
        station: formData.station,
        icao: formData.icao,
        mails: formData.mails.join(";"),
        comments: formData.comments,
        active: formData.active,
      });
    }
    toggle(false);
    setEditData(null);
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            {mode.charAt(0).toUpperCase() + mode.slice(1)} Station Mails
          </ModalHeader>
          <ModalBody>
            <div className="mb-1">
              <TextInput
                label="Station"
                name="station"
                icon={faPlaneDeparture}
              />
            </div>
            <div className="mb-1">
              <TextInput label="ICAO" name="icao" icon={faPlaneDeparture} />
            </div>
            <FormGroup>
              <Controller
                name="active"
                control={formMethods.control}
                render={({ field }) => (
                  <Checkbox
                    inputId="active"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.checked)}
                    binary={true}
                  />
                )}
              />
              <Label className="form-check-label ms-1" for="active">
                Is Active
              </Label>
            </FormGroup>
            <div className="mb-1">
              <ChipsInput label="mails" name="mails" />
            </div>
            <div className="mb-1">
              <TextInput
                label="Comments"
                name="comments"
                type="textarea"
                rows="3"
                icon={faComment}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default StationMailsModal;
